<template>
  <v-col cols="12">
    <data-table
      :headers="headers"
      :items="obCollection.getModelList()"
      :options="pagination"
      :total="serverItemsLength"
      :last-page="serverLastPage"
      :loading="bLocalLoading"
      btn-action-path="categories"
      btn-action-item-key-id="id"
      hide-btn-action-view
      is-route-name
      @delete="deleteItem"
      @update:sort-by="onSortBy"
      @update:sort-desc="onSortDesc"
      @update:options="onPagination"
      @update:page="onPage"
    >
      <template #[`item.active`]="{ item }">
        <active-icon :active="item.active" />
      </template>

      <template #[`item.name`]="{ item }">
        <name-with-avatar
          :name="item.name"
          :to="{ name: 'categories.update', params: { id: item.id } }"
        />
      </template>

      <template #[`item.parent_id`]="{ item }">
        <view-name :id="item.parent_id"></view-name>
      </template>

      <template #[`item.actions`]="{ item }">
        <module-actions :value="item.id" @delete="deleteItem" />
      </template>
    </data-table>
    <categories-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CategoriesForm from "@/modules/categories/components/Form.vue";
import CategoriesMixin from "@/modules/categories/mixins/CategoriesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import ViewName from "@/modules/categories/components/ViewName.vue";
import type { DataTableHeader } from "vuetify";
import { AppModule } from "@/store/app";
import type { Category } from "@planetadeleste/vue-mc-shopaholic";
import Utils from "@/services/Utils";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";

@Component({
  components: { NameWithAvatar, ViewName, ActiveIcon, CategoriesForm },
})
export default class List extends Mixins(CategoriesMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "code", value: "code" },
      {
        text: "parent.category",
        value: "parent_id",
        sortable: false,
      },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.company.selected", this.index);
    this.addEvent(`${this.sModelName}.after.save`, this.onAfterSave);
    this.addEvent(`${this.sModelName}.after.delete`, this.onAfterDelete);
    this.addEvent("before.show.form", this.getCode);
  }

  onAfterSave() {
    this.index();
    this.onAfterDelete();
  }

  async onAfterDelete() {
    await AppModule.loadCategories();
  }

  getCode(obItem: Category) {
    if (obItem.id || obItem.code) {
      return;
    }

    Utils.nextCategoryCode().then((response) => {
      this.obItem.set("code", response.data);
    });
  }
}
</script>
